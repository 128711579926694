<template>
    <div>
        <HeaderNavigation :info="$store.state.infos" :checkValue="5" />
        <div class="top_pic"><img :src="banner" alt=""></div>

        <div class="shop_da_box">
            <div style="text-align:center" class="title1">菜品展示</div>
            <div class="shop_box">
                <div class="nav_title_box">
                    <div @click="classClick(item)" :class="item.id==classValue?'yes_item_class_box':'item_class_box'" v-for="(item,index) of titleList" :key="index">{{item.type_name}}</div>
                </div>
                <div class="nav_content_box">
                    <div class="item_content_box" v-for="(item,index) of shopList" :key="index">
                        <div class="nei_item_content_box">
                            <div class="item_content_box_pic"><img :src="'https://qiangshu888.com/'+item.product_image" alt=""></div>
                            <div class="item_content_box_title">{{item.product_name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      <BottomNavigation :info="$store.state.infos" />
      <RightMenu :info="$store.state.infos" @openpop="openpop" />
      <JoinBulletFrame v-if="JoinModal" @closepop="closepop" />
    </div>
</template>

<script>
    import {type_list,product_list,cateBanner} from '@/api/index.js'
    import HeaderNavigation from "@/components/header_navigation.vue";
    import BottomNavigation from "@/components/bottom_navigation.vue";
    import RightMenu from "@/components/right-menu.vue";
    import JoinBulletFrame from "@/components/joinBulletFrame.vue";
    export default {
        components: {
            HeaderNavigation,
            BottomNavigation,
            RightMenu,
            JoinBulletFrame
        },
        data(){
            return{
                classValue:1,
                titleList:[],
                banner:'',
                shopList:[],
                JoinModal:false,
            }
        },
        created() {
            window.scroll(0, 0)
            this.type_list()
            this.cateBanner()
        },
        methods:{
          cateBanner(){
                cateBanner({id:8}).then(res=>{
                    this.banner = res.data
                })
            },
            type_list(){
                type_list().then(res=>{
                    this.classValue = res.data[0].id
                    this.titleList = res.data
                    this.product_list()
                })
            },
            product_list(){
                product_list({id:this.classValue}).then(res=>{
                    this.shopList = res.data
                })
            },
            classClick(e){
                this.classValue = e.id
                this.product_list()
            },
            openpop(e){
                this.JoinModal = e
            },
            closepop(e){
                this.JoinModal = e
            },
        }
    }
</script>

<style scoped>
.top_pic{
    width: 100%;
    height: 600px;
}
.top_pic img{
    width: 100%;
    height: 100%;
  object-fit: fill;
}
.shop_da_box{
  width: 901px;
  margin: 0 auto;
  padding: 50px 0;
}
.shop_box{
  width: 100%;
  margin-top: 30px;
}
.nav_title_box{
  display: flex;
  align-items: center;
  width: 100%;
  overflow: auto;
}
.item_class_box{
  width: 100px;
  border-radius: 12px;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
}
.yes_item_class_box{
  width: 100px;
  border-radius: 12px;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
  background: #A91800;
  color: #FFF;
}
.nav_content_box{
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.nav_content_box::after{
  width: 240px;
  height: 190px;
  display: block;
  content: ' ';
}
/* .item_content_box{
  width: 33.33%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
} */
.nei_item_content_box{
  width: 240px;
  height:220px;
  margin-bottom: 20px;
  background: #F7F7F7;
}
.item_content_box_pic{
  width: 100%;
  height: 180px;
}
.item_content_box_pic img{
  width: 100%;
  height:100%;
  object-fit: cover;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.item_content_box_title{
  text-align: center;
  margin: 2px 0;
  height: 40px;
  background: #035331;
  align-items: center;
  justify-content: center;
  color: #fff;
  display: flex;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.title1 {
  font-size: 16px;
  font-weight: 600;
}
</style>